@use 'src/theme/common-style';
@use 'src/theme/font';

.sectionDivider {
    margin: 1rem -20px; /* section component padding is 20px */
}

.values-grid {
    display: grid;
    column-gap: 1rem;
    row-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
