$gray-0: #f5f7fb;
$gray-1: #949494;
$gray-2: #999;
$gray-3: #f1f1f1;
$gray-4: #61798f;
$gray-5: #8c92b1;
$gray-6: #2e384d;
$gray-7: #e1e6ee;
$white-0: #fff;
$black-0: #1a1a1a;
$blue-0: #aae7e8;
$blue-1: #006ed1;
$blue-2: #0762c8;
$teal-0: #49cdcf;
$green-0: #2db744;
$green-1: #b0cf36;
$yellow-1: #ffd277;
$red-0: #f00;
$steelBlue900: #272d33;
