@use 'src/theme/common-style';
@use 'src/theme/font';
@use 'src/theme/colour';
@use 'src/theme/font-family';

.section {
    padding: 1rem;
    margin-top: 16px;
    background-color: colour.$white-0;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgb(122 130 145 / 8%);
}
