@use 'src/theme/colour';
@use 'src/theme/common-style';

.wrapper {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: colour.$white-0;
    border-color: colour.$gray-1;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    @include common-style.flexbox-row;
}