@use 'src/theme/colour';
@use 'src/theme/font';
@use 'src/theme/font-family';

@mixin button($backgroundColour, $borderColour, $textColour, $hoverBackground, $hoverText) {
    display: block;
    padding: 0.5rem 2.5rem;
    font-family: font-family.$raleway-semibold;
    font-size: 0.875rem;
    line-height: 1.5;
    color: $textColour;
    letter-spacing: normal;
    white-space: nowrap;
    cursor: pointer;
    background-color: $backgroundColour;
    border-color: $borderColour;
    border-style: solid;
    border-width: 2px;
    border-radius: 9999px;

    &:hover:not(:disabled) {
        color: $hoverText;
        background-color: $hoverBackground;
        border-color: $borderColour;
    }
}

// #4d4d4d

.black {
    @include button(
        colour.$black-0,
        colour.$black-0,
        colour.$white-0,
        colour.$white-0,
        colour.$black-0
    );
}

.white {
    @include button(
        colour.$white-0,
        colour.$black-0,
        colour.$black-0,
        colour.$black-0,
        colour.$white-0
    );
}

.blue {
    @include button(colour.$blue-0, colour.$blue-0, colour.$black-0, transparent, colour.$blue-0);
}

.disabled {
    cursor: default;
    opacity: 0.5;
}
