@use 'src/theme/common-style';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.fundingWrapper {
    padding: 1rem;
    background-color: #fff;

    .sectionItem {
        span {
            font: 15px font-family.$raleway-bold;
            line-height: 22px;
            letter-spacing: 0.21px;
        }
    }

    .btn {
        width: 100%;
        margin-top: 2rem;
    }
}

.bankIcon {
    display: flex;
    align-items: center;
}
