@use '@keyliving/component-lib/dist/scss/mixins';
@use '@keyliving/component-lib/dist/scss/utils';
@use 'src/theme/radius';
@use 'src/theme/font-family';

.tile {
    position: relative;
    display: flex;
    padding: 1.75rem 1.5rem;
    flex-direction: column;
    border: 1px solid #{utils.color('grey', 200)};
    border-radius: radius.$radius-3xl;
    justify-content: space-between;
}

.tile__label {
    font-family: font-family.$raleway-regular;
    font-size: 0.875rem;
    line-height: 1.3;
}

.tile__content {
    margin-top: 2.5rem;
    font-family: font-family.$rawline-semibold;
    font-size: 1.5rem;
    line-height: 1;
}
