@use 'src/theme/common-style';

.textIcon {
    @include common-style.flexbox-row;

    align-items: center;

    img {
        width: 24px;
        height: 24px;
    }

    div {
        padding: 0 10px;
    }
}