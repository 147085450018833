@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/font';
@use 'src/theme/colour';
@use 'src/theme/font-family';

.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    column-gap: 1rem;
}

.header {
    @include font.fine-print(font-family.$raleway-bold);

    line-height: 1.5;
    color: colour.$gray-2;
    letter-spacing: 1.13px;
}

.info {
    display: flex;
    @include font.fine-print;

    color: colour.$gray-2;
    align-items: center;

    div {
        padding-right: 10px;
    }
}

.infoIcon {
    margin-top: 3px;
    cursor: pointer;
}
