@use '@keyliving/component-lib/dist/scss/mixins';
@use '@keyliving/component-lib/dist/scss/utils';
@use 'src/theme/colour';
@use 'src/theme/font-family';
@use 'src/theme/font';
@use 'src/theme/common-style';
@use 'src/theme/radius';

:root {
    --dashboard-padding: 1rem;
}

@include mixins.screen('xl2') {
    :root {
        --dashboard-padding: 2rem;
    }
}

.text--red {
    color: utils.color('red', 500);
}

.text--green {
    color: utils.color('green', 900);
}

.header {
    padding-bottom: 4rem;
    margin-bottom: -4rem;
    background-color: colour.$black-0;
    background-image: url('../../assets/icons/KeyBrandPattern.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 80%;

    @include mixins.screen('xl') {
        padding-bottom: 5rem;
        margin-bottom: -5rem;
        background-size: 75% auto;
    }

    @include mixins.screen('xl3') {
        background-size: 50% auto;
    }
}

.header__content {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include mixins.screen('xl') {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.header__title {
    color: colour.$white-0;
    @include font.header-3(font-family.$butler-regular);

    @include mixins.screen('lg') {
        @include font.header-1;
    }
}

.header__invoice {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #fff;

    @include mixins.screen('lg') {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.header__invoice-summary {
    padding: 0 var(--dashboard-padding);
    margin-top: 2rem;
    font-family: font-family.$raleway-medium;
    font-size: 1.5rem;
    line-height: 1.5;
}

.invoice {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    background-color: #333;
    border-radius: radius.$radius-3xl;
}

.invoice__col {
    padding: 1.25rem var(--dashboard-padding);
}

.invoice__col--icon {
    padding: 1.25rem;
    flex-grow: 0;
    background-color: #2e2e2e;
}

.invoice__icon {
    padding: 1.25rem;
    color: #989898;
    background-color: colour.$black-0;
    border-radius: 9999px;
}

.invoice__cta {
    display: flex;
    font-family: font-family.$raleway-semibold;
    font-size: 0.875rem;
    line-height: 1.5;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
}

.invoice__withdraw-date {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #989898;
}

.invoice__amount {
    margin-top: 1.5rem;
    font-family: font-family.$raleway-semibold;
    font-size: 1.5rem;
    line-height: 1.5;
    color: #cbcbcb;
}

.dashboard-wrapper {
    padding-bottom: 1rem;
    background: #fff;
    border-radius: radius.$radius-3xl;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 8px 10px -6px rgb(0 0 0 / 10%);
}

.as-of-date {
    display: flex;
    padding: 1rem var(--dashboard-padding) 1.5rem var(--dashboard-padding);
    font-size: 0.75rem;
    color: utils.color('grey', 700);
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;

    @include mixins.screen('xl2') {
        padding-top: 2.375rem;
    }
}

.dashboard {
    display: grid;
    grid-template-columns: 1fr;

    @include mixins.screen('xl2') {
        grid-template-columns: 1fr 1fr;
    }

    @include mixins.screen('xl3') {
        grid-template-columns: 3fr 2fr;
    }
}

.dashboard__performance {
    padding: 1rem var(--dashboard-padding);

    @include mixins.screen('xl2') {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.equity-graph-header {
    display: block;

    @include mixins.screen('xl2') {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
    }
}

.home-value {
    padding: 0 var(--dashboard-padding);
    text-align: center;

    @include mixins.screen('xl2') {
        text-align: left;
    }
}

.home-value__label {
    font-family: font-family.$raleway-regular;
    font-size: 0.875rem;
    line-height: 1.3;
}

.home-value__content {
    font-family: font-family.$rawline-semibold;
    font-size: 2.25rem;
    line-height: 1.1;
}

.filter {
    margin-top: 2rem;

    @include mixins.screen('xl2') {
        margin-top: 0;
    }
}

.performance-header {
    padding: 0 1.5rem; // Same as the tiles
    font-family: font-family.$raleway-semibold;
    font-size: 1.125rem;
    line-height: 1.2;
}

.performance {
    display: grid;
    margin-top: 1.25rem;
    grid-template-columns: 1fr;
    gap: 0.375rem;

    @include mixins.screen('lg') {
        grid-template-columns: 1fr 1fr;
    }
}

.performance__equity-value {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    justify-content: space-between;
}

.performance__equity-value-trend {
    display: flex;
    font-family: font-family.$raleway-semibold;
    font-size: 0.875rem;
    align-items: center;
    gap: 0.25rem;
}

.performance__impact {
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
}

.performance__impact-info {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.performance__impact-interval {
    font-family: font-family.$raleway-regular;
    font-size: 0.875rem;
    color: utils.color('grey', 500);
}

.equity {
    padding: 1.75rem 1.5rem;
    margin-top: 1.125rem;
    font-family: font-family.$raleway-regular;
    font-size: 0.875rem;
    line-height: 1.3;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-top-left-radius: radius.$radius-3xl;
    border-top-right-radius: radius.$radius-3xl;
}

.equity--rounded {
    border-bottom-right-radius: radius.$radius-3xl;
    border-bottom-left-radius: radius.$radius-3xl;
}

.equity__title {
    font-family: font-family.$raleway-semibold;
    font-size: 1rem;
}

.equity__body {
    margin-top: 1em;
}

.equity__cta {
    margin-top: 1rem;
}

.co-financing {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.75rem 1.5rem;
    margin-top: 0.25rem;
    font-family: font-family.$raleway-medium;
    font-size: 0.875rem;
    line-height: 1.3;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-bottom-right-radius: radius.$radius-3xl;
    border-bottom-left-radius: radius.$radius-3xl;
    gap: 0.25rem;
}

.co-financing:hover {
    background-color: #efefef;
}

.co-financing__info {
    display: none;

    @include mixins.screen('md') {
        display: block;
    }
}

.co-financing__cta {
    margin-left: auto;
}

.body {
    display: grid;
    gap: 1rem;
    padding-bottom: 4rem; // adds a bit of over scroll

    @include mixins.screen('xl2') {
        grid-template-columns: 2fr minmax(25rem, 1fr);
    }
}

.title {
    color: colour.$black-0;
    @include font.header-4;

    @include mixins.screen('xl2') {
        color: colour.$white-0;
    }
}

.title--rev {
    color: colour.$white-0;
}
