@use 'src/theme/colour';
@use 'src/theme/font';
@use 'src/theme/font-family';

@mixin inner-wrapper-styles {
    width: 100%;
    max-width: 1280px;
    padding: 0 16px;
    margin: 0 auto;
}

@mixin flexbox-row {
    display: flex;
    flex-direction: row;
}

@mixin flexbox-column {
    display: flex;
    flex-direction: column;
}

@mixin header {
    @include inner-wrapper-styles;
    @include flexbox-row;

    justify-content: flex-start;

    div {
        color: colour.$black-0;
        @include font.header-3(font-family.$raleway-bold);
    }
}
