@use 'src/theme/colour';
@use 'src/theme/common-style';
@use 'src/theme/font-family';

.contactFormWrapper {
    height: fit-content;
    padding: 20px;
    background: colour.$white-0;
    border-radius: 3px;
}

.contactFormContent {
    @include common-style.flexbox-column;

    gap: 20px;
}

.contactFormContent .heading {
    font: 12px font-family.$raleway-bold;
    line-height: 14px;
    color: colour.$gray-5;
    letter-spacing: 1.13px;
}

.contactFormContent p {
    margin: unset;
    font: 16px font-family.$raleway-regular;
    line-height: 22px;
}

.contactFormContent .textInput textarea {
    width: 100%;
    height: 220px;
    max-height: 220px;
}

.contactFormContent .uploadButton {
    width: 100px;
    height: 30px;
    margin-right: 10px;
}

.contactFormContent .submitButton {
    height: 40px;
    margin: 10px 0;
}
