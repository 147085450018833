@use 'src/theme/font';
@use 'src/theme/common-style';

$small: 959px;

.subSection {
    padding: 15px 0;
    @include font.paragraph;
    @include common-style.flexbox-row;
}

.icon {
    @include common-style.flexbox-row;

    justify-content: center;
    width: 25%;

    img {
        width: 60px;
        height: 80px;
    }
}

.button {
    width: 25%;
    padding: 10px 0;
    margin-top: 25px;
    margin-bottom: 30px;
}

@media screen and (max-width: $small) {
    .subSection {
        @include common-style.flexbox-column;

        align-items: center;
        justify-content: flex-start;
    }

    .button {
        width: 65%;
    }
}
