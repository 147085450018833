@use 'src/theme/font';
@use 'src/theme/colour';
@use 'src/theme/font-family';

.wrapper + .wrapper {
    margin-top: 2rem;
}

.header {
    display: flex;
    margin-bottom: 1.25rem;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.header__text {
    @include font.fine-print(font-family.$raleway-bold);

    line-height: 1.5;
    color: colour.$gray-2;
    letter-spacing: 1.13px;
    flex-grow: 1;
}

.clear {
    color: colour.$blue-1;
    @include font.caption(font-family.$raleway-bold);
}

.options {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.option {
    padding: 1rem;
    font-size: 1rem;
    white-space: nowrap;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid colour.$blue-0;
    border-radius: 9999px;
    @include font.caption(font-family.$raleway-bold);
}

.option--selected {
    background-color: colour.$blue-0;
    border: 1px solid colour.$blue-0;
}
