@use 'src/theme/font';
@use 'src/theme/font-family';
@use 'src/theme/common-style';
@use 'src/theme/colour';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: grid;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0 0 0 / 40%);
    align-items: center;
}

.modalContent {
    position: relative;
    width: 100%;
    max-width: 700px;
    padding: 25px;
    margin: auto;
    background-color: colour.$white-0;
    border: 1px solid colour.$gray-2;
    border-radius: 3px;
}

.close {
    cursor: pointer;
}

.modalHeader {
    min-height: 50px;
    margin-bottom: 0.5em;
    line-height: 1.1;
    color: black;
    justify-content: space-between;
    align-items: center;
    @include font.header-4(font-family.$raleway-bold);
    @include common-style.flexbox-row;
}

.modalBodyTitle {
    @include font.paragraph(font-family.$raleway-bold);

    padding-bottom: 5px;
    color: colour.$black-0;
}

.modalBodyParagraph {
    @include font.paragraph;

    padding-bottom: 25px;
    color: colour.$black-0;
}

.modalFooter {
    @include common-style.flexbox-row;

    justify-content: center;
}

.closeButton {
    width: 100%;
    max-width: 300px;
}
