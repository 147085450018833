@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/colour';
@use 'src/theme/common-style';
@use 'src/theme/font';
@use 'src/theme/font-family';

.row {
    @include common-style.flexbox-row;
}

.checkbox {
    margin-right: 1rem;
}

.textInput {
    margin: 25px 0;
}

.button {
    width: 100%;
    padding: 10px 0;
    margin-top: 25px;
    margin-bottom: 30px;

    @include mixins.screen('xl') {
        width: 60%;
    }
}

.bold {
    @include font.paragraph(font-family.$raleway-bold);
}

.buttonContainer {
    @include common-style.flexbox-column;
}

.errMsg {
    margin-top: 2rem;
    color: colour.$red-0;
}

.totalAmountContainer,
.adminFeeContainer {
    @include common-style.flexbox-column;

    margin: 25px 0;
}

.divider {
    margin-left: 0;
    border: 1px solid colour.$gray-1;
}
