@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';
@use 'src/theme/font';
@use 'src/theme/font-family';
@use 'src/theme/colour';
@use '../../../../components/Charts/colors.module.scss';

.wrapper {
    display: grid;
    gap: 1rem;
    margin-top: 2rem;

    @include mixins.screen('xl2') {
        grid-template-columns: 2fr minmax(25rem, 1fr);
    }
}

.equity {
    @include font.paragraph(font-family.$raleway-bold);

    color: colors.$equityValue;
}

.investment {
    @include font.paragraph(font-family.$raleway-bold);

    color: colors.$equityPurchased;
}

.sectionDivider {
    margin: 25px 0;
}

.change {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

.buttonWrapper {
    width: 100%;
    @include common-style.flexbox-row;

    justify-content: center;
    margin: 25px 0;

    .button {
        width: 100%;
        max-width: 375px;
    }
}

.filter {
    padding: 0 var(--dashboard-padding); // defined in home/Page.module.scss
    margin-top: 2rem;

    @include mixins.screen('xl2') {
        margin-top: 0;
    }
}

.legend {
    display: flex;
    align-items: center;
    gap: 1rem;

    .legend__icon {
        width: 60px;
        height: 2px;
        background-color: #000;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .legend__content {
        flex: 1;
        flex-grow: 1;
    }

    .legend__title {
        @include font.caption(font-family.$raleway-bold);

        margin-bottom: 0.25rem;
    }
}

.legend + .legend {
    margin-top: 1.5rem;
}
