@use 'src/theme/colour';
@use 'src/theme/font-family';
@use 'src/theme/common-style';
@use 'src/theme/font';

.sectionWrapper {
    height: fit-content;
    padding: 1rem;
    background-color: colour.$white-0;
}

.sectionHeading {
    font: 12px font-family.$raleway-bold;
    line-height: 14px;
    color: colour.$gray-5;
    text-transform: uppercase;
    letter-spacing: 1.13px;
}

.sectionContent {
    margin: 20px 0;
    @include common-style.flexbox-column;

    gap: 20px;

    .sectionItem {
        span {
            font: 15px font-family.$raleway-medium;
            line-height: 22px;
            letter-spacing: 0.21px;
        }
    }

    .docWrapper {
        @include common-style.flexbox-row;

        width: 50%;
        max-width: 100%;
        justify-content: space-between;

        .docName {
            font: 15px font-family.$raleway-medium;
            line-height: 22px;
            letter-spacing: 0.21px;
        }

        .linkText {
            font: 15px font-family.$raleway-medium;
            line-height: 22px;
            color: colour.$blue-2;
            letter-spacing: 0.21px;
        }
    }
}

.sectionTextInput {
    border-radius: 2px;

    input {
        width: 100%;
    }
}

%button {
    width: 100%;
    max-width: 350px;
}

.sectionButton {
    height: 40px;
    @extend %button;

    border-radius: 3px;
}

.chipButton {
    @extend %button;
}

.chipButtonSave {
    @extend %button;

    color: white;
    background-color: black;
}

.bankIcon {
    display: flex;
    align-items: center;
}
