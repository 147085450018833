@use 'src/theme/font-family';

@font-face {
    font-family: font-family.$raleway-regular;
    src: url('./fonts/Raleway/Raleway-Regular.ttf');
}

@font-face {
    font-family: font-family.$raleway-semibold;
    src: url('./fonts/Raleway/Raleway-SemiBold.ttf');
}

@font-face {
    font-family: font-family.$raleway-bold;
    src: url('./fonts/Raleway/Raleway-Bold.ttf');
}

@font-face {
    font-family: font-family.$raleway-medium;
    src: url('./fonts/Raleway/Raleway-Medium.ttf');
}

@font-face {
    font-family: font-family.$butler-regular;
    src: url('./fonts/Butler/Butler_Regular.otf');
}

@font-face {
    font-family: font-family.$rawline-light;
    src: url('./fonts/Rawline/rawline-300.ttf');
}

@font-face {
    font-family: font-family.$rawline-medium;
    src: url('./fonts/Rawline/rawline-500.ttf');
}

@font-face {
    font-family: font-family.$rawline-semibold;
    src: url('./fonts/Rawline/rawline-600.ttf');
}

@font-face {
    font-family: font-family.$rawline-bold;
    src: url('./fonts/Rawline/rawline-700.ttf');
}

@mixin header-1 {
    font-family: font-family.$butler-regular;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: normal;
}

@mixin header-2($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 48px;
}

@mixin header-3($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 36px;
}

@mixin header-4($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 24px;
}

@mixin header-5($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 20px;
}

@mixin header-6($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 18px;
}

@mixin paragraph($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: normal;
}

@mixin caption($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: normal;
}

@mixin fine-print($font: font-family.$raleway-regular) {
    font-family: $font;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
}

@mixin number-large($font: font-family.$rawline-light) {
    font-family: $font;
    font-size: 44px;
    line-height: 61px;
    letter-spacing: -0.15px;
}

@mixin number-medium($font: font-family.$rawline-light) {
    font-family: $font;
    font-size: 26px;
    line-height: 1;
    letter-spacing: -0.09px;
}

@mixin number-small($font: font-family.$rawline-light) {
    font-family: $font;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: normal;
}
