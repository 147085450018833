@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/colour';
@use 'src/theme/font';
@use 'src/theme/font-family';
@use 'src/theme/common-style';

.sectionHeading {
    font: 12px font-family.$raleway-bold;
    line-height: 14px;
    color: colour.$gray-5;
    text-transform: uppercase;
    letter-spacing: 1.13px;
}

.sectionContent {
    display: grid;
    margin: 20px 0;
    gap: 20px;
}

.sectionTextInput {
    border-radius: 2px;
}

.newFundingWrapper {
    @include common-style.flexbox-column;

    gap: 48px;
    margin-top: 30px;
}

.currentFundingWrapper {
    @include common-style.flexbox-column;

    gap: 16px;
}

.bankIcon {
    display: flex;
    align-items: center;
}

.continueChipButton {
    width: 100%;
    height: 40px;
}

@media (max-width: 768px) {
    .modalWrapper {
        width: 50% !important;
    }
}

@media (max-width: 580px) {
    .modalWrapper {
        width: 70% !important;
    }
}

@media (max-width: 411px) {
    .modalWrapper {
        width: 91% !important;
    }
}

@media (max-width: 960px) {
    .sectionWrapper {
        width: 80vw;
    }

    .sectionContent {
        .docWrapper {
            @include common-style.flexbox-column;

            width: 100%;
        }
    }
}
