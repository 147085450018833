/* stylelint-disable selector-pseudo-class-no-unknown */
@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/colour';
@use 'src/theme/font';
@use 'src/theme/font-family';
@use 'src/theme/common-style';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    color: colour.$white-0;
    background-color: colour.$black-0;
}

.container {
    display: flex;
    width: 100%;
    max-width: 1280px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    flex-shrink: 0;
    flex-grow: 0;
}

.main-nav-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    background-color: #fff;
    box-shadow: 10px 10px 10px -3px rgb(0 0 0 / 7%), 4px 4px 3px -4px rgb(0 0 0 / 7%);

    @include mixins.screen('lg') {
        position: static;
        display: block;
        background-color: transparent;
        box-shadow: none;
        flex-grow: 1;
    }
}

.main-nav-wrapper--open {
    display: block;
}

.main-nav {
    @include mixins.screen('lg') {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

.navList {
    @include common-style.flexbox-row;

    align-items: center;
    padding-left: 20px;
    margin: 0;
}

.mainNavItem {
    position: relative;
    list-style: none;
    cursor: pointer;
    @include font.paragraph(font-family.$raleway-bold);

    > a {
        display: flex;
        padding: 1.5rem 1rem;
        color: #000;
        text-decoration: none;
        align-items: center;
        gap: 0.5rem;

        @include mixins.screen('lg') {
            padding: 2rem 1rem;
            color: #fff;
        }
    }

    &:hover > a,
    > :global(.active) {
        color: colour.$blue-1;

        @include mixins.screen('lg') {
            color: colour.$blue-0;
        }
    }
}

.dropdown {
    @include mixins.screen('lg') {
        position: absolute;
        top: 100%;
        left: -1rem;
        z-index: 1;
        display: none;
        width: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
        list-style-type: none;
        background-color: colour.$white-0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: 10px 10px 10px -3px rgb(0 0 0 / 7%), 4px 4px 3px -4px rgb(0 0 0 / 7%);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    a {
        display: block;
        padding: 1rem 2rem;
        color: colour.$black-0;
        text-decoration: none;
        @include font.paragraph(font-family.$raleway-bold);

        &:hover {
            color: colour.$blue-1;
        }
    }

    :global(.active) {
        color: colour.$blue-1;
    }
}

.mainNavItem--financials {
    margin-right: auto;
    @include font.paragraph(font-family.$raleway-bold);

    &:hover .dropdown {
        display: block;
    }
}

.mainNavItem--logout {
    padding: 2rem 1rem;

    @include mixins.screen('lg') {
        padding: 0;
    }

    button {
        width: 100%;
        height: 40px;
        border-radius: 3px;
    }
}

.hamburger {
    display: block;
    padding: 1rem;
    color: colour.$white-0;

    @include mixins.screen('lg') {
        display: none;
    }
}
