@use '@keyliving/component-lib/dist/scss/utils';
@use 'src/theme/common-style';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.filterWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: center;
}

.filter {
    padding: 0.25rem 0.75rem;
    font-family: font-family.$raleway-semibold;
    font-size: 0.875rem;
    line-height: 1.75;
    background-color: #fff;
    border-radius: 7px;
}

.filter:focus {
    outline: none;
}

.filter--selected {
    color: #fff;
    background-color: colour.$steelBlue900;
}

.filter:not(.filter--selected):hover {
    background-color: utils.color('grey', 200);
}
