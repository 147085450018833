@use '@keyliving/component-lib/dist/scss/mixins';
@use '@keyliving/component-lib/dist/scss/utils';
@use 'src/theme/common-style';
@use 'src/theme/font';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.body {
    display: grid;
    gap: 1rem;
    padding-bottom: 4rem; // adds a bit of over scroll

    @include mixins.screen('xl2') {
        grid-template-columns: 3fr minmax(25rem, 2fr);
        grid-auto-flow: dense;
    }
}

.financialSummaryWrapper {
    padding: 1rem;
    background-image: url('../../../../assets/icons/KeyLogoTealBackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.financialSummary {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include mixins.screen('md') {
        flex-direction: row;
        /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
        flex-wrap: wrap;
    }

    .financialSummary__detail {
        padding: 1rem;
        white-space: nowrap;
        flex-basis: 100%;
        flex-shrink: 0;
        /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
        flex-grow: 1;

        @include mixins.screen('md') {
            flex-basis: 50%;
        }

        @include mixins.screen('xl') {
            flex-basis: auto;
            justify-content: space-between;
        }
     
        @include mixins.screen('xl2') {
            flex-basis: 50%;
        }

        @media only screen and (min-width: 1280px) {
            flex-basis: auto;
        }

    }
}

.buyEquityButtonWrapper {
    width: 100%;
    padding: 1rem;
}

.transactionsWrapper {
    @include common-style.flexbox-column;

    padding: 2rem 1rem;
    background-color: #fff;
}

.transaction-group {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #{utils.color('grey', 200)};

    .transaction-group__heading {
        @include font.caption(font-family.$raleway-bold);

        width: 100%;
    }
}

.transaction-header {
    color: utils.color('grey', 400);
    @include font.caption(font-family.$raleway-bold);
}

.transaction {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    gap: 0.5rem;

    @include mixins.screen('md') {
        flex-wrap: wrap;
        gap: 1rem;
    }
}

.transaction__label {
    flex: 1;
    flex-grow: 1;
    margin-right: auto;
    white-space: nowrap;

    @include mixins.screen('md') {
        flex: 2;
    }
}

.transaction__change,
.transaction__coi-total {
    text-align: right;

    @include mixins.screen('md') {
        flex-basis: 125px;
    }
}

.loadMoreButtonWrapper {
    width: 100%;
    padding: 2rem 1rem;
}

.dateContainer {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    gap: 1rem;

    @include mixins.screen('md') {
        flex-direction: row;
    }
}

.button {
    width: 100%;
    margin: 0 auto;

    @include mixins.screen('md') {
        width: 250px;
    }
}

.filters {
    padding: 1rem;
    background-color: #fff;
}
