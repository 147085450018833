@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';

.wrapper {
    margin-top: 2rem;

    @include mixins.screen('xl2') {
        margin-top: 3rem;
    }
}

.legend {
    display: flex;
    padding: 0 var(--dashboard-padding); // defined in home/Page.module.scss
    margin-bottom: 2rem;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    @include mixins.screen('xl2') {
        justify-content: flex-start;
    }
}

.legend__label {
    display: flex;
    font-size: 0.75rem;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
}

.legend__label-color {
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 999px;
}
