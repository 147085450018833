@use 'src/theme/font';
@use 'src/theme/colour';

%text-input-style {
    width: 100%;
    resize: none;
    border-color: colour.$gray-2;
    border-width: 1px;
    border-radius: 2px;
    @include font.paragraph;
}

.textInput {
    @extend %text-input-style;

    padding: 1rem;
}

.textInputUneditable {
    padding: 12px;
    resize: none;
    border: none;
    outline: none;
    @include font.paragraph;
}

.textInputWithLabel {
    padding: 23px 15px 7px;
}

.label {
    position: absolute;
    margin-top: 9px;
    margin-left: 17px;
    color: colour.$gray-1;
    @include font.fine-print;
}
