@use 'src/theme/colour';
@use 'src/theme/common-style';
@use 'src/theme/font-family';

.FaqWrapper {
    background: colour.$white-0;
    border-radius: 3px;
}

.FaqFormHeading {
    padding: 20px;
    font: 12px font-family.$raleway-bold;
    line-height: 14px;
    color: colour.$gray-5;
    letter-spacing: 1.13px;
}

.faqFormContent .section {
    margin: 30px 0;
}

.faqFormContent .section .heading {
    margin: 20px;
    font: 14px font-family.$raleway-bold;
    line-height: 18px;
    color: colour.$blue-2;
    text-transform: uppercase;
}

.faqFormContent .section .faqPointHeader {
    font: 16px font-family.$rawline-medium;
    line-height: 22px;
    color: colour.$gray-6;
}

.faqFormContent .section .faqPointBody {
    padding-left: 27px;

    p {
        font: 16px font-family.$raleway-regular;
        line-height: 22px;
    }
}
