@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/colour';
@use 'src/theme/common-style';

.wrapper {
    position: relative;
    padding-top: 3.5rem; // height of the menu
    background-color: colour.$gray-0;

    @include mixins.screen('lg') {
        padding-top: 5.25rem; // height of the menu
    }
}
