@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';

.container {
    display: grid;
    min-height: 100vh;
    padding: 1rem;
    background-color: #fff;
    gap: 1rem;
    grid-template-rows: 12rem 1fr;
    grid-template-columns: 1fr;

    @include mixins.screen('xl') {
        place-items: center;
        grid-auto-flow: dense;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }

    @include mixins.screen('xl5') {
        grid-template-columns: 1fr 2fr;
    }

    .rightCol {
        position: relative;

        @include mixins.screen('xl') {
            grid-column: 2 / 3;
            place-self: stretch;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;

            @include mixins.screen('xl') {
                object-position: center;
            }
        }
    }

    .leftCol {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        grid-column: 1 / 2;
        align-items: center;
        gap: 10px;
        @include common-style.flexbox-column;

        @include mixins.screen('xl') {
            max-width: 500px;
        }
    }
}
