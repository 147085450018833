@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';

.header {
    @include common-style.header;

    margin: 48px auto;
}

.body {
    display: grid;
    gap: 1rem;
    padding-bottom: 4rem; // adds a bit of over scroll

    @include mixins.screen('xl2') {
        grid-template-columns: 1fr 1fr;
    }
}
