@use '@keyliving/component-lib/dist/scss/utils';
@use 'src/theme/colour';
@use 'src/theme/font';
@use 'src/theme/font-family';
@use 'src/theme/common-style';

.wrapper {
    @include common-style.flexbox-column;
}

.row {
    @include common-style.flexbox-row;

    align-items: flex-start;
}

.label {
    margin-bottom: 0.25rem;
    font-family: font-family.$raleway-medium;
    font-size: 0.75rem;
    color: utils.color('grey', 500);
}

%percent {
    @include font.caption(font-family.$raleway-medium);
}

.positivePercent {
    color: colour.$green-0;
    @extend %percent;
}

.negativePercent {
    color: colour.$red-0;
    @extend %percent;
}

.caption {
    color: colour.$gray-1;
    @include font.fine-print;
}

.link {
    color: colour.$blue-1;
    @include font.fine-print;

    text-decoration: none;
}

.superscriptLarge {
    @include font.number-medium(font-family.$rawline-medium);

    top: 0;
    margin: 0;
}

.valueLarge {
    @include font.number-large;

    margin-top: -8px;
}

.superscriptMedium {
    @include font.number-small(font-family.$rawline-medium);

    margin-top: 8px;
}

.valueMedium {
    @include font.number-medium;
}

.valueSmall {
    @include font.number-small;
}
