@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';
@use 'src/theme/colour';
@use 'src/theme/font';

.wrapper {
    margin: 1rem 0;
}

.navTab {
    @include mixins.screen('md') {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.tab {
    display: flex;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.1;
    color: colour.$gray-4;
    text-decoration: none;
    letter-spacing: normal;
    white-space: nowrap;
    cursor: pointer;
    flex-direction: row;
    background-color: colour.$gray-3;
    border: solid 1px colour.$gray-2;
    border-top: none;
    justify-content: center;
    flex-basis: 25%;
    flex-grow: 0;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    flex-shrink: 1;

    @include mixins.screen('md') {
        border-top: solid 1px colour.$gray-2;
    }

    &:first-child {
        border-top: solid 1px colour.$gray-2;

        @include mixins.screen('md') {
            border-right: none;
        }
    }
}

.tab--selected {
    color: colour.$black-0;
    background-color: colour.$white-0;
    @include font.paragraph('Raleway-Bold');
}
