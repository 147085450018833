@use 'src/theme/colour';

.accordianWrapper {
    padding: 26px 1rem;
    cursor: pointer;
    background: colour.$white-0;
    box-shadow: 0 -1px 0 0 colour.$gray-7, 0 1px 0 0 colour.$gray-7;
}

.accordianHeading {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.accordianBody {
    padding-top: 26px;
}
