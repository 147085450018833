@use 'src/theme/colour';
@use 'src/theme/common-style';
@use 'src/theme/font';
@use 'src/theme/font-family';

$small: 640px;

.equityPurchaseContainer {
    width: 90%;
}

.row {
    @include common-style.flexbox-row;
}

.section {
    @include font.paragraph;
}

.purchaseRangeContainer {
    display: flex;
    justify-content: center;
    margin: 30px 20%;
    gap: 30px;
}

.purchaseRangeWrapper {
    @include common-style.flexbox-column;

    justify-content: center;
    gap: 5px;
}

.subHeading {
    font-size: 12px;
    line-height: 16px;
    color: colour.$gray-4;
    @include font.paragraph(font-family.$raleway-regular);
}

.rangeDateContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    color: colour.$gray-6;
}

.rangeMonth {
    @include font.paragraph(font-family.$rawline-medium);

    flex: 1;
    font-size: 22px;
}

.rangeDate {
    @include font.paragraph(font-family.$rawline-light);

    font-size: 44px;
}

.rangeTime {
    @include font.paragraph(font-family.$rawline-medium);

    font-size: 22px;
    line-height: 30px;
    color: colour.$gray-6;
}

@media screen and (max-width: $small) {
    .equityPurchaseContainer {
        width: 100%;
    }

    .button {
        width: 65%;
    }

    .purchaseRangeContainer {
        @include common-style.flexbox-column;

        align-items: center;
    }
}
