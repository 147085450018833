@use 'src/theme/common-style';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.filterWrapper {
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
}

.filter {
    font: 1rem font-family.$raleway-medium;
    line-height: 1.5;
}

.filter:focus {
    outline: none;
}

.filter--selected {
    font-family: font-family.$raleway-bold;
    color: colour.$black-0;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;
}
