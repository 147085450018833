@use 'src/theme/colour';
@use 'src/theme/font';
@use 'src/theme/common-style';

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownButton {
    width: 100%;
    padding: 15px;
    color: colour.$gray-1;
    cursor: pointer;
    background-color: colour.$white-0;
    border-color: colour.$gray-2;
    border-width: 1px;
    justify-content: space-between;
    @include font.paragraph;
    @include common-style.flexbox-row;
}

.dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: colour.$white-0;
    box-shadow: 5px 10px 10px 3px rgb(0 0 0 / 7%), 0 4px 3px 4px rgb(0 0 0 / 7%);

    .option {
        width: 100%;
        padding: 15px;
        color: colour.$gray-1;
        text-align: left;
        text-decoration: none;
        cursor: pointer;
        @include font.paragraph;
    }
}

.dropdownContent div:hover {
    background-color: colour.$gray-3;
}
