@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';

.header {
    @include common-style.header;

    margin-top: 48px;
    margin-bottom: 18px;
}

.body {
    display: grid;
    gap: 1rem;
    padding-bottom: 4rem; // adds a bit of over scroll

    @include mixins.screen('xl2') {
        grid-template-columns: 2fr minmax(25rem, 1fr);
    }
}
